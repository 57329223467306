import React, { useState, useEffect } from 'react';
import { Calendar, Truck, Clipboard } from 'lucide-react';

// =============================
// 1) Your API endpoint
// =============================
const API_BASE = "https://fc2nuqicp9.execute-api.us-east-2.amazonaws.com";

// =============================
// 2) CREATE (POST)
// =============================
async function createAppointment(appointment) {
  const res = await fetch(`${API_BASE}/appointments`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(appointment),
  });
  if (!res.ok) {
    const text = await res.text();
    throw new Error(`Failed to create appointment: ${res.status} - ${text}`);
  }
  return res.json();
}

// =============================
// 3) UPDATE (PUT)
//  - appointment: the original item (has scheduledDate + appointmentId keys)
//  - updates: object with fields to update (status, arrivalDate, etc.)
//  - If the user changes scheduledDate in 'updates', the backend will do a "move" (delete + put).
// =============================
async function updateAppointment(appointment, updates) {
  // appointment.appointmentId = e.g. "123"
  // If you still want to pass ?date=..., you can keep it:
  const url = `${API_BASE}/${appointment.appointmentId}?date=${appointment.scheduledDate}`;
  
  const res = await fetch(url, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(updates),
  });
  if (!res.ok) {
    const text = await res.text();
    throw new Error(`Failed to update appointment: ${res.status} - ${text}`);
  }
  return res.json();
}

// =============================
// 4) GET single day
// =============================
async function fetchAppointmentsByDate(dateString) {
  const url = `${API_BASE}?date=${dateString}`;
  const res = await fetch(url);
  if (!res.ok) {
    const text = await res.text();
    throw new Error(`Failed to fetch day: ${res.status} - ${text}`);
  }
  return res.json(); // array
}

// =============================
// 5) GET a week's worth
// =============================
async function fetchWeekAppointments(startDate) {
  const days = [];
  const d = new Date(startDate);
  d.setHours(0, 0, 0, 0);

  for (let i = 0; i < 7; i++) {
    days.push(d.toISOString().split("T")[0]);
    d.setDate(d.getDate() + 1);
  }
  const results = await Promise.all(days.map((day) => fetchAppointmentsByDate(day)));
  return results.flat();
}

// =============================
// Available time slots for appts
// =============================
const AVAILABLE_TIME_SLOTS = [
  "08:00","09:00","10:00","11:00","12:00",
  "13:00","14:00","15:00","16:00","17:00",
];

function Inbound() {
  // Toggle between 'calendar' and 'list'
  const [view, setView] = useState("calendar");

  // Modals: new appointment, arrival, completion, edit
  const [showForm, setShowForm] = useState(false);
  const [showArrivalForm, setShowArrivalForm] = useState(false);
  const [showCompletionForm, setShowCompletionForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  // The entire week's appointments
  const [appointments, setAppointments] = useState([]);
  // The selected item for start/complete/edit
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  // The new or editing form data
  const [formData, setFormData] = useState({
    scheduler: "",
    client: "",
    carrierName: "",
    isSecure: false,
    loadType: "live-unload",
    palletCount: 0,
    poNumber: "",
    dateEntered: new Date().toISOString(),
    scheduledDate: "",
    scheduledTime: "",
    arrivalDate: "",
    arrivalTime: "",
    isConforming: false,
    issues: "",
  });

  // Start date for the displayed week
  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return now;
  });

  // On mount or whenever startDate/view changes, fetch from DB
  useEffect(() => {
    fetchWeekAppointments(startDate)
      .then((data) => setAppointments(data))
      .catch((err) => console.error(err));
  }, [view, startDate]);

  // =============================
  // CREATE: handleNewAppointmentSubmit
  // =============================
  const handleNewAppointmentSubmit = async (e) => {
    e.preventDefault();

    // (Optional) check local data for double-book
    const isDoubleBooked = appointments.some(
      (apt) =>
        apt.scheduledDate === formData.scheduledDate &&
        apt.scheduledTime === formData.scheduledTime &&
        apt.status !== "cancelled"
    );
    if (isDoubleBooked) {
      const confirm = window.confirm("This time slot is booked. Continue?");
      if (!confirm) return;
    }

    // Build the appointment object
    const newApt = {
      appointmentId: Date.now().toString(),
      scheduler: formData.scheduler,
      client: formData.client,
      carrierName: formData.carrierName,
      isSecure: formData.isSecure,
      loadType: formData.loadType,
      palletCount: formData.palletCount,
      poNumber: formData.poNumber,
      dateEntered: new Date().toISOString(), // auto-set
      scheduledDate: formData.scheduledDate,
      scheduledTime: formData.scheduledTime,
      arrivalDate: "",
      arrivalTime: "",
      isConforming: false,
      issues: "",
      status: "scheduled",
    };

    try {
      await createAppointment(newApt);
      const updated = await fetchWeekAppointments(startDate);
      setAppointments(updated);
      setShowForm(false);

      // Reset
      setFormData({
        scheduler: "",
        client: "",
        carrierName: "",
        isSecure: false,
        loadType: "live-unload",
        palletCount: 0,
        poNumber: "",
        dateEntered: new Date().toISOString(),
        scheduledDate: "",
        scheduledTime: "",
        arrivalDate: "",
        arrivalTime: "",
        isConforming: false,
        issues: "",
      });
    } catch (err) {
      console.error(err);
      alert("Failed to create: " + err.message);
    }
  };

  // =============================
  // START: set to in-progress
  // =============================
  const startAppointment = (apt) => {
    setSelectedAppointment(apt);
    setFormData((prev) => ({
      ...prev,
      arrivalDate: new Date().toISOString().split("T")[0],
      arrivalTime: "08:00",
    }));
    setShowArrivalForm(true);
  };
  const handleArrivalSubmit = async (e) => {
    e.preventDefault();
    if (!selectedAppointment) return;
    const updates = {
      status: "in-progress",
      arrivalDate: formData.arrivalDate,
      arrivalTime: formData.arrivalTime,
    };
    try {
      await updateAppointment(selectedAppointment, updates);
      const updated = await fetchWeekAppointments(startDate);
      setAppointments(updated);

      setShowArrivalForm(false);
      setSelectedAppointment(null);
      setFormData({ ...formData, arrivalDate: "", arrivalTime: "" });
    } catch (err) {
      console.error(err);
      alert("Failed to start: " + err.message);
    }
  };

  // =============================
  // COMPLETE
  // =============================
  const completeAppointment = (apt) => {
    setSelectedAppointment(apt);
    setFormData((prev) => ({
      ...prev,
      isConforming: false,
      issues: "",
    }));
    setShowCompletionForm(true);
  };
  const handleCompletionSubmit = async (e) => {
    e.preventDefault();
    if (!selectedAppointment) return;
    const updates = {
      status: "completed",
      isConforming: formData.isConforming,
      issues: formData.issues,
    };
    try {
      await updateAppointment(selectedAppointment, updates);
      const updated = await fetchWeekAppointments(startDate);
      setAppointments(updated);

      setShowCompletionForm(false);
      setSelectedAppointment(null);
      setFormData((prev) => ({ ...prev, isConforming: false, issues: "" }));
    } catch (err) {
      console.error(err);
      alert("Failed to complete: " + err.message);
    }
  };

  // =============================
  // CANCEL
  // =============================
  const cancelAppointment = async (apt) => {
    const updates = { status: "cancelled" };
    try {
      await updateAppointment(apt, updates);
      const updated = await fetchWeekAppointments(startDate);
      setAppointments(updated);
    } catch (err) {
      console.error(err);
      alert("Failed to cancel: " + err.message);
    }
  };

  // =============================
  // EDIT
  // Only if status === 'scheduled'
  // If user changes scheduledDate, the backend "move" logic takes over
  // =============================
  const editAppointment = (apt) => {
    if (apt.status !== "scheduled") {
      alert("Cannot edit unless status is 'scheduled'");
      return;
    }
    setSelectedAppointment(apt);
    setFormData({
      ...formData,
      scheduler: apt.scheduler,
      client: apt.client,
      carrierName: apt.carrierName,
      isSecure: apt.isSecure,
      loadType: apt.loadType,
      palletCount: apt.palletCount,
      poNumber: apt.poNumber,
      scheduledDate: apt.scheduledDate,
      scheduledTime: apt.scheduledTime,
    });
    setShowEditForm(true);
  };
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!selectedAppointment) return;
    // The user might have changed scheduledDate or appointmentId
    // and the backend will do "move" if the PK changed
    const updates = {
      scheduler: formData.scheduler,
      client: formData.client,
      carrierName: formData.carrierName,
      isSecure: formData.isSecure,
      loadType: formData.loadType,
      palletCount: formData.palletCount,
      poNumber: formData.poNumber,
      scheduledDate: formData.scheduledDate,
      scheduledTime: formData.scheduledTime,
    };
    try {
      await updateAppointment(selectedAppointment, updates);
      const updated = await fetchWeekAppointments(startDate);
      setAppointments(updated);
      setShowEditForm(false);
      setSelectedAppointment(null);
    } catch (err) {
      console.error(err);
      alert("Failed to edit: " + err.message);
    }
  };

  // =============================
  // CalendarView
  // =============================
  function CalendarView() {
    const goPrevWeek = () => {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() - 7);
      setStartDate(newDate);
    };
    const goNextWeek = () => {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() + 7);
      setStartDate(newDate);
    };

    function getWeekDays(dateObj) {
      const days = [];
      const start = new Date(dateObj);
      start.setHours(0, 0, 0, 0);
      for (let i = 0; i < 7; i++) {
        days.push(new Date(start));
        start.setDate(start.getDate() + 1);
      }
      return days;
    }
    const weekDays = getWeekDays(startDate);

    return (
      <div className="bg-white rounded shadow p-4">
        <div className="flex items-center justify-between mb-4">
          <button onClick={goPrevWeek} className="px-3 py-1 bg-gray-100 rounded hover:bg-gray-200">
            Prev
          </button>
          <h2 className="font-bold text-lg">Week of {startDate.toLocaleDateString()}</h2>
          <button onClick={goNextWeek} className="px-3 py-1 bg-gray-100 rounded hover:bg-gray-200">
            Next
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-7 gap-4">
          {weekDays.map(day => {
            const dayString = day.toISOString().split("T")[0];
            return (
              <div key={dayString} className="border rounded p-2">
                <h3 className="font-semibold mb-2">
                  {day.toLocaleDateString("en-US", {
                    weekday: "long", month: "short", day: "numeric"
                  })}
                </h3>
                <div className="space-y-2">
                  {AVAILABLE_TIME_SLOTS.map(time => {
                    const slotApts = appointments.filter(
                      (apt) =>
                        apt.scheduledDate === dayString &&
                        apt.scheduledTime === time &&
                        apt.status !== "cancelled"
                    );
                    return (
                      <div key={time} className="flex items-center gap-2">
                        <span className="w-16 text-sm">{time}</span>
                        {slotApts.length > 0 ? (
                          <div className="bg-blue-100 text-blue-800 px-2 py-1 rounded text-sm flex-1">
                            {slotApts.map(apt => (
                              <div key={apt.appointmentId}>
                                {apt.carrierName} - {apt.poNumber}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="bg-gray-50 text-gray-600 px-2 py-1 rounded text-sm flex-1">
                            Available
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  // =============================
  // ListView
  // =============================
  function ListView() {
    return (
      <div className="bg-white rounded shadow">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Date/Time</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Client</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Carrier</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">PO Number</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {appointments.map((apt) => (
              <tr key={apt.appointmentId}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {apt.scheduledDate} {apt.scheduledTime}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{apt.client}</td>
                <td className="px-6 py-4 whitespace-nowrap">{apt.carrierName}</td>
                <td className="px-6 py-4 whitespace-nowrap">{apt.poNumber}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      apt.status === "scheduled"
                        ? "bg-yellow-100 text-yellow-800"
                        : apt.status === "in-progress"
                        ? "bg-blue-100 text-blue-800"
                        : apt.status === "completed"
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {apt.status}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  {apt.status === "scheduled" && (
                    <>
                      <button
                        onClick={() => startAppointment(apt)}
                        className="text-blue-600 hover:text-blue-900 mr-3"
                      >
                        Start
                      </button>
                      <button
                        onClick={() => cancelAppointment(apt)}
                        className="text-red-600 hover:text-red-900 mr-3"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => editAppointment(apt)}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit
                      </button>
                    </>
                  )}
                  {apt.status === "in-progress" && (
                    <button
                      onClick={() => completeAppointment(apt)}
                      className="text-green-600 hover:text-green-900"
                    >
                      Complete
                    </button>
                  )}
                  {/* if completed or cancelled, no further actions */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  // =============================
  // Return the main UI
  // =============================
  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto">
        {/* Top navbar for toggling views & new apt */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-gray-900">Inbound Appointments</h1>
          <div className="flex gap-4">
            <button
              onClick={() => setView("calendar")}
              className={`px-4 py-2 rounded-lg ${
                view === "calendar" ? "bg-blue-600 text-white" : "bg-white text-gray-600 border"
              }`}
            >
              <Calendar className="inline-block mr-2 h-5 w-5" />
              Calendar View
            </button>
            <button
              onClick={() => setView("list")}
              className={`px-4 py-2 rounded-lg ${
                view === "list" ? "bg-blue-600 text-white" : "bg-white text-gray-600 border"
              }`}
            >
              <Clipboard className="inline-block mr-2 h-5 w-5" />
              List View
            </button>
            <button
              onClick={() => setShowForm(true)}
              className="bg-green-600 text-white px-4 py-2 rounded-lg flex items-center"
            >
              <Truck className="mr-2 h-5 w-5" />
              New Appointment
            </button>
          </div>
        </div>

        {view === "calendar" ? <CalendarView /> : <ListView />}

        {/* Modal: New Appointment */}
        {showForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 max-w-4xl w-full">
              <h2 className="text-2xl font-bold mb-4">Schedule New Appointment</h2>
              <form onSubmit={handleNewAppointmentSubmit} className="space-y-4">
                {/* SCHEDULER */}
                <div>
                  <label className="block text-sm font-medium mb-1">Scheduler</label>
                  <input
                    type="text"
                    required
                    value={formData.scheduler}
                    onChange={(e) => setFormData({ ...formData, scheduler: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                {/* CLIENT */}
                <div>
                  <label className="block text-sm font-medium mb-1">Client</label>
                  <input
                    type="text"
                    required
                    value={formData.client}
                    onChange={(e) => setFormData({ ...formData, client: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                {/* CARRIER */}
                <div>
                  <label className="block text-sm font-medium mb-1">Carrier Name</label>
                  <input
                    type="text"
                    required
                    value={formData.carrierName}
                    onChange={(e) => setFormData({ ...formData, carrierName: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                {/* SECURE? */}
                <div>
                  <label className="block text-sm font-medium mb-1">Secure Load?</label>
                  <select
                    value={formData.isSecure ? "yes" : "no"}
                    onChange={(e) => setFormData({ ...formData, isSecure: e.target.value === "yes" })}
                    className="w-full p-2 border rounded"
                  >
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </div>
                {/* LOAD TYPE */}
                <div>
                  <label className="block text-sm font-medium mb-1">Load Type</label>
                  <select
                    value={formData.loadType}
                    onChange={(e) => setFormData({ ...formData, loadType: e.target.value })}
                    className="w-full p-2 border rounded"
                  >
                    <option value="live-unload">Live Unload</option>
                    <option value="container-drop">Container Drop</option>
                  </select>
                </div>
                {/* PALLET COUNT */}
                <div>
                  <label className="block text-sm font-medium mb-1">Pallet Count</label>
                  <input
                    type="number"
                    required
                    min="1"
                    value={formData.palletCount}
                    onChange={(e) =>
                      setFormData({ ...formData, palletCount: parseInt(e.target.value) || 0 })
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
                {/* PO Number */}
                <div>
                  <label className="block text-sm font-medium mb-1">PO Number</label>
                  <input
                    type="text"
                    required
                    value={formData.poNumber}
                    onChange={(e) => setFormData({ ...formData, poNumber: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                {/* Scheduled Date */}
                <div>
                  <label className="block text-sm font-medium mb-1">Scheduled Date</label>
                  <input
                    type="date"
                    required
                    value={formData.scheduledDate}
                    onChange={(e) => setFormData({ ...formData, scheduledDate: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                {/* Scheduled Time */}
                <div>
                  <label className="block text-sm font-medium mb-1">Scheduled Time</label>
                  <select
                    required
                    value={formData.scheduledTime}
                    onChange={(e) => setFormData({ ...formData, scheduledTime: e.target.value })}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Select a time</option>
                    {AVAILABLE_TIME_SLOTS.map(time => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </select>
                </div>

                {/* Buttons */}
                <div className="flex justify-end gap-4 mt-4">
                  <button
                    type="button"
                    onClick={() => setShowForm(false)}
                    className="px-4 py-2 border border-gray-300 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded"
                  >
                    Schedule
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Modal: Arrival */}
        {showArrivalForm && selectedAppointment && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
              <h2 className="text-2xl font-bold mb-4">Record Truck Arrival</h2>
              <form onSubmit={handleArrivalSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">Arrival Date</label>
                  <input
                    type="date"
                    required
                    value={formData.arrivalDate}
                    onChange={(e) => setFormData({ ...formData, arrivalDate: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Arrival Time</label>
                  <input
                    type="time"
                    required
                    value={formData.arrivalTime}
                    onChange={(e) => setFormData({ ...formData, arrivalTime: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="flex justify-end gap-4 mt-4">
                  <button
                    type="button"
                    onClick={() => {
                      setShowArrivalForm(false);
                      setSelectedAppointment(null);
                    }}
                    className="px-4 py-2 border border-gray-300 rounded"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
                    Record Arrival
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Modal: Completion */}
        {showCompletionForm && selectedAppointment && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 max-w-md w-full">
              <h2 className="text-2xl font-bold mb-4">Complete Appointment</h2>
              <form onSubmit={handleCompletionSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">Conforming?</label>
                  <select
                    value={formData.isConforming ? "yes" : "no"}
                    onChange={(e) => setFormData({ ...formData, isConforming: e.target.value === "yes" })}
                    className="w-full p-2 border rounded"
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Issues</label>
                  <textarea
                    rows={3}
                    value={formData.issues}
                    onChange={(e) => setFormData({ ...formData, issues: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div className="flex justify-end gap-4 mt-4">
                  <button
                    type="button"
                    onClick={() => {
                      setShowCompletionForm(false);
                      setSelectedAppointment(null);
                    }}
                    className="px-4 py-2 border border-gray-300 rounded"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded">
                    Complete
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Modal: Edit (only if scheduled) */}
        {showEditForm && selectedAppointment && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg p-6 max-w-4xl w-full">
              <h2 className="text-2xl font-bold mb-4">Edit Appointment ({selectedAppointment.appointmentId})</h2>
              <form onSubmit={handleEditSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">Scheduler</label>
                  <input
                    type="text"
                    required
                    value={formData.scheduler}
                    onChange={(e) => setFormData({ ...formData, scheduler: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Client</label>
                  <input
                    type="text"
                    required
                    value={formData.client}
                    onChange={(e) => setFormData({ ...formData, client: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Carrier Name</label>
                  <input
                    type="text"
                    required
                    value={formData.carrierName}
                    onChange={(e) => setFormData({ ...formData, carrierName: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Secure Load?</label>
                  <select
                    value={formData.isSecure ? "yes" : "no"}
                    onChange={(e) =>
                      setFormData({ ...formData, isSecure: e.target.value === "yes" })
                    }
                    className="w-full p-2 border rounded"
                  >
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Load Type</label>
                  <select
                    value={formData.loadType}
                    onChange={(e) => setFormData({ ...formData, loadType: e.target.value })}
                    className="w-full p-2 border rounded"
                  >
                    <option value="live-unload">Live Unload</option>
                    <option value="container-drop">Container Drop</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Pallet Count</label>
                  <input
                    type="number"
                    required
                    min="1"
                    value={formData.palletCount}
                    onChange={(e) =>
                      setFormData({ ...formData, palletCount: parseInt(e.target.value) || 0 })
                    }
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">PO Number</label>
                  <input
                    type="text"
                    required
                    value={formData.poNumber}
                    onChange={(e) => setFormData({ ...formData, poNumber: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Scheduled Date</label>
                  <input
                    type="date"
                    required
                    value={formData.scheduledDate}
                    onChange={(e) => setFormData({ ...formData, scheduledDate: e.target.value })}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium mb-1">Scheduled Time</label>
                  <select
                    required
                    value={formData.scheduledTime}
                    onChange={(e) => setFormData({ ...formData, scheduledTime: e.target.value })}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Select a time</option>
                    {AVAILABLE_TIME_SLOTS.map(time => (
                      <option key={time} value={time}>{time}</option>
                    ))}
                  </select>
                </div>

                <div className="flex justify-end gap-4 mt-4">
                  <button
                    type="button"
                    onClick={() => {
                      setShowEditForm(false);
                      setSelectedAppointment(null);
                    }}
                    className="px-4 py-2 border border-gray-300 rounded"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-600 text-white rounded"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Inbound;
