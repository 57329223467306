import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import { AlertCircle } from 'lucide-react';
import KSPLogo from './KSP Logo.jpg';

const ForceChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { username, currentPassword } = location.state || {};

  const validatePasswords = () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      return false;
    }
    return true;
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!validatePasswords()) {
      return;
    }
    
    setLoading(true);

    try {
      if (!username || !currentPassword) {
        throw new Error('Username or current password not found. Please try logging in again.');
      }

      // First sign in again to get the challenge
      const { nextStep } = await signIn({
        username,
        password: currentPassword
      });

      // Then confirm sign in with the new password
      const { isSignedIn } = await confirmSignIn({
        challengeResponse: newPassword
      });

      if (isSignedIn) {
        navigate('/login', { 
          state: { message: 'Password changed successfully. Please login with your new password.' }
        });
      }
    } catch (error) {
      console.error('Change password error:', error);
      setError(error.message || 'Failed to change password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!username || !currentPassword) {
    navigate('/login');
    return null;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-slate-900">
      <div className="bg-white/10 backdrop-blur-sm p-8 rounded-lg shadow-xl max-w-md w-full">
        <div className="flex justify-center mb-6">
          <img src={KSPLogo} alt="KSP Logo" className="w-48 h-auto" />
        </div>
        <h2 className="text-center text-white text-lg font-semibold mb-4">Change Your Password</h2>

        <form onSubmit={handleChangePassword} className="space-y-4">
          <div>
            <label className="text-white text-sm">New Password</label>
            <input
              type="password"
              className="w-full px-4 py-2 bg-gray-800 text-white border border-gray-600 rounded-lg"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              disabled={loading}
              autoComplete="new-password"
            />
          </div>

          <div>
            <label className="text-white text-sm">Confirm New Password</label>
            <input
              type="password"
              className="w-full px-4 py-2 bg-gray-800 text-white border border-gray-600 rounded-lg"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              disabled={loading}
              autoComplete="new-password"
            />
          </div>

          {error && (
            <div className="text-red-400 text-sm flex items-center">
              <AlertCircle className="w-4 h-4 mr-2" />
              {error}
            </div>
          )}

          <button
            type="submit"
            className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition"
            disabled={loading}
          >
            {loading ? 'Changing...' : 'Change Password'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForceChangePassword;