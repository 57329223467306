import {
  CognitoIdentityProviderClient,
  AdminCreateUserCommand,
  ListUsersCommand,
  AdminGetUserCommand,
  AdminDisableUserCommand,
  AdminEnableUserCommand,
  AdminResetUserPasswordCommand,
  AdminSetUserPasswordCommand,
  AdminConfirmSignUpCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { fetchAuthSession } from 'aws-amplify/auth';

const USER_POOL_ID = 'us-east-1_JzOHkpJjj';
const REGION = 'us-east-1';

/**
 * Fetch AWS credentials from Cognito authentication session
 */
const getCredentials = async () => {
  try {
    const session = await fetchAuthSession();
    if (!session?.credentials) {
      throw new Error('No credentials available');
    }

    return {
      region: REGION,
      credentials: {
        accessKeyId: session.credentials.accessKeyId,
        secretAccessKey: session.credentials.secretAccessKey,
        sessionToken: session.credentials.sessionToken,
      }
    };
  } catch (error) {
    console.error('Error getting credentials:', error);
    throw new Error('Failed to obtain credentials: ' + error.message);
  }
};

/**
 * Create a new Cognito user with optional password and force change password setting
 */
export const createUser = async ({ email, phoneNumber, givenName, familyName, password, forceChangePassword }) => {
  try {
    console.log('Getting credentials for user creation...');
    const config = await getCredentials();
    const client = new CognitoIdentityProviderClient(config);
    const name = `${givenName} ${familyName}`;

    console.log('Creating user in Cognito...');
    // Step 1: Create the user in Cognito
    const createUserCommand = new AdminCreateUserCommand({
      UserPoolId: USER_POOL_ID,
      Username: email,
      UserAttributes: [
        { Name: 'email', Value: email },
        { Name: 'email_verified', Value: 'true' },
        ...(phoneNumber ? [{ Name: 'phone_number', Value: phoneNumber }] : []),
        { Name: 'given_name', Value: givenName },
        { Name: 'family_name', Value: familyName },
        { Name: 'name', Value: name },
      ],
      DesiredDeliveryMediums: ['EMAIL'],
      ForceAliasCreation: true,
      MessageAction: 'SUPPRESS', // Suppress automatic email
    });

    await client.send(createUserCommand);
    
    console.log(`User ${email} created successfully.`);

    // Step 2: Set the password explicitly
    if (password) {
      console.log(`Setting password for user ${email}...`);
      await client.send(new AdminSetUserPasswordCommand({
        UserPoolId: USER_POOL_ID,
        Username: email,
        Password: password,
        Permanent: !forceChangePassword, // If true, requires password change on first login
      }));
    }

    // Step 3: Confirm the user manually if they shouldn’t be forced to change password
    if (!forceChangePassword) {
      console.log(`Confirming user ${email}...`);
      await client.send(new AdminConfirmSignUpCommand({
        UserPoolId: USER_POOL_ID,
        Username: email,
      }));
    }

    return { success: true };
  } catch (error) {
    console.error('Error creating user:', error);
    throw new Error(error.message || 'Failed to create user.');
  }
};

/**
 * List all users from Cognito
 */
export const listUsers = async () => {
  try {
    const config = await getCredentials();
    const client = new CognitoIdentityProviderClient(config);

    const command = new ListUsersCommand({
      UserPoolId: USER_POOL_ID,
      Limit: 60
    });

    const response = await client.send(command);
    
    if (!response.Users) return [];

    return response.Users.map(user => ({
      Username: user.Username,
      Enabled: user.Enabled,
      UserStatus: user.UserStatus,
      UserCreateDate: user.UserCreateDate,
      Attributes: user.Attributes || []
    }));
  } catch (error) {
    console.error('Error listing users:', error);
    throw new Error(error.message || 'Failed to retrieve users.');
  }
};

/**
 * Get user details from Cognito
 */
export const getUser = async (username) => {
  try {
    const config = await getCredentials();
    const client = new CognitoIdentityProviderClient(config);

    const command = new AdminGetUserCommand({
      UserPoolId: USER_POOL_ID,
      Username: username,
    });

    return await client.send(command);
  } catch (error) {
    console.error('Error fetching user:', error);
    if (error.name === 'UserNotFoundException') throw new Error('User not found');
    throw error;
  }
};

/**
 * Disable a Cognito user
 */
export const disableUser = async (username) => {
  try {
    const config = await getCredentials();
    const client = new CognitoIdentityProviderClient(config);

    const command = new AdminDisableUserCommand({
      UserPoolId: USER_POOL_ID,
      Username: username,
    });

    await client.send(command);
    return { success: true, message: 'User disabled successfully' };
  } catch (error) {
    console.error('Error disabling user:', error);
    if (error.name === 'UserNotFoundException') throw new Error('User not found');
    throw error;
  }
};

/**
 * Enable a Cognito user
 */
export const enableUser = async (username) => {
  try {
    const config = await getCredentials();
    const client = new CognitoIdentityProviderClient(config);

    const command = new AdminEnableUserCommand({
      UserPoolId: USER_POOL_ID,
      Username: username,
    });

    await client.send(command);
    return { success: true, message: 'User enabled successfully' };
  } catch (error) {
    console.error('Error enabling user:', error);
    if (error.name === 'UserNotFoundException') throw new Error('User not found');
    throw error;
  }
};

/**
 * Reset a user's password
 */
export const resetUserPassword = async (username) => {
  try {
    const config = await getCredentials();
    const client = new CognitoIdentityProviderClient(config);

    const command = new AdminResetUserPasswordCommand({
      UserPoolId: USER_POOL_ID,
      Username: username,
    });

    await client.send(command);
    return { success: true, message: 'Password reset initiated successfully' };
  } catch (error) {
    console.error('Error resetting password:', error);
    if (error.name === 'UserNotFoundException') throw new Error('User not found');
    throw error;
  }
};
