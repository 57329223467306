import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';
import { fetchAuthSession, signIn, signOut, resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import KSPLogo from './KSP Logo.jpg';
import { Link } from 'react-router-dom';

const forgotPassword = async ({ username }) => {
    return resetPassword({ username });
};
  
const forgotPasswordSubmit = async ({ username, code, newPassword }) => {
    return confirmResetPassword({ username, confirmationCode: code, newPassword });
};


const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1); // 1: Request code, 2: Submit code and new password
  const navigate = useNavigate();

  const handleRequestCode = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await forgotPassword({ username: email });
      setStep(2);
    } catch (error) {
      console.error('Forgot password error:', error);
      setError(error.message || 'Failed to request password reset. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await forgotPasswordSubmit({ username: email, code, newPassword });
      navigate('/login', { state: { message: 'Password reset successfully. Please login with your new password.' } });
    } catch (error) {
      console.error('Reset password error:', error);
      setError(error.message || 'Failed to reset password. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900 relative overflow-hidden">
      {/* Enhanced animated background */}
      <div className="absolute inset-0 overflow-hidden">
        {/* Animated gradient mesh */}
        <div className="absolute inset-0 opacity-30">
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-pink-500/20 animate-gradient-x" />
          <div className="absolute inset-0 bg-gradient-to-b from-yellow-500/10 via-transparent to-blue-500/10 animate-gradient-y" />
        </div>
        
        {/* Floating orbs */}
        <div className="absolute w-96 h-96 bg-blue-500/20 rounded-full blur-3xl animate-float-slow top-0 -right-48" />
        <div className="absolute w-96 h-96 bg-purple-500/20 rounded-full blur-3xl animate-float-slower -top-48 -left-48" />
        <div className="absolute w-96 h-96 bg-yellow-500/10 rounded-full blur-3xl animate-float -bottom-48 left-1/4" />
        
        {/* Particle effect dots */}
        <div className="absolute inset-0">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className={`absolute w-1 h-1 bg-white/30 rounded-full animate-twinkle-${i % 5}`}
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                animationDelay: `${Math.random() * 3}s`
              }}
            />
          ))}
        </div>
        
        {/* Overlay blur */}
        <div className="absolute inset-0 bg-slate-900/60 backdrop-blur-sm" />
      </div>

      <div className="relative w-full max-w-md mx-4 transition-all duration-1000 opacity-100 translate-y-0">
        {/* Animated logo container */}
        <div className="flex justify-center mb-12 transition-transform duration-700 hover:scale-105">
          <div className="p-6 rounded-2xl bg-white/5 backdrop-blur-sm border border-white/10 shadow-2xl animate-glow">
            <img
              src={KSPLogo}
              alt="KSP Logo"
              className="w-64 h-auto opacity-90 transition-all duration-500 hover:opacity-100"
            />
          </div>
        </div>

        {/* Enhanced forgot password form with animations */}
        <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-2xl p-8 shadow-2xl 
                      transition-all duration-500 hover:shadow-blue-500/10 animate-glow">
          {step === 1 ? (
            <form onSubmit={handleRequestCode} className="space-y-6">
              <div className="space-y-2 transition-all duration-300 hover:translate-x-1">
                <label htmlFor="email" className="block text-sm font-medium text-slate-200 mb-2">
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  required
                  className="w-full px-4 py-2 bg-slate-800/50 border border-slate-700 rounded-lg 
                           text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-2 
                           focus:ring-blue-500 focus:border-transparent transition-all duration-300
                           hover:bg-slate-800/70 focus:scale-[1.02]"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={loading}
                />
              </div>

              {error && (
                <div className="flex items-center text-red-400 text-sm p-3 bg-red-500/10 border 
                             border-red-500/20 rounded-lg animate-shake">
                  <AlertCircle className="w-4 h-4 mr-2 flex-shrink-0" />
                  <span>{error}</span>
                </div>
              )}

              <button
                type="submit"
                disabled={loading}
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg font-medium
                         hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 
                         focus:ring-offset-2 focus:ring-offset-slate-800 disabled:opacity-50 
                         disabled:cursor-not-allowed transition-all duration-300 hover:scale-[1.02]
                         hover:shadow-lg hover:shadow-blue-500/25"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                  </div>
                ) : (
                  'Request Reset Code'
                )}
              </button>
            </form>
          ) : (
            <form onSubmit={handleResetPassword} className="space-y-6">
              <div className="space-y-2 transition-all duration-300 hover:translate-x-1">
                <label htmlFor="code" className="block text-sm font-medium text-slate-200 mb-2">
                  Reset Code
                </label>
                <input
                  id="code"
                  type="text"
                  required
                  className="w-full px-4 py-2 bg-slate-800/50 border border-slate-700 rounded-lg 
                           text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-2 
                           focus:ring-blue-500 focus:border-transparent transition-all duration-300
                           hover:bg-slate-800/70 focus:scale-[1.02]"
                  placeholder="Enter the reset code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  disabled={loading}
                />
              </div>

              <div className="space-y-2 transition-all duration-300 hover:translate-x-1">
                <label htmlFor="newPassword" className="block text-sm font-medium text-slate-200 mb-2">
                  New Password
                </label>
                <input
                  id="newPassword"
                  type="password"
                  required
                  className="w-full px-4 py-2 bg-slate-800/50 border border-slate-700 rounded-lg 
                           text-slate-200 placeholder-slate-400 focus:outline-none focus:ring-2 
                           focus:ring-blue-500 focus:border-transparent transition-all duration-300
                           hover:bg-slate-800/70 focus:scale-[1.02]"
                  placeholder="Enter your new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  disabled={loading}
                />
              </div>

              {error && (
                <div className="flex items-center text-red-400 text-sm p-3 bg-red-500/10 border 
                             border-red-500/20 rounded-lg animate-shake">
                  <AlertCircle className="w-4 h-4 mr-2 flex-shrink-0" />
                  <span>{error}</span>
                </div>
              )}

          <button
                type="submit"
                disabled={loading}
                className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg font-medium
                         hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 
                         focus:ring-offset-2 focus:ring-offset-slate-800 disabled:opacity-50 
                         disabled:cursor-not-allowed transition-all duration-300 hover:scale-[1.02]
                         hover:shadow-lg hover:shadow-blue-500/25 mb-4"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                  </div>
                ) : (
                  'Reset Password'
                )}
              </button>

              <Link 
                to="/login"
                className="block w-full text-center px-4 py-2 bg-slate-700 text-slate-200 rounded-lg font-medium
                         hover:bg-slate-600 transition-all duration-300 hover:scale-[1.02]"
              >
                Back to Login
              </Link>
            </form>
          )}
          <Link 
                to="/login"
                className="block w-full text-center px-4 py-2 bg-slate-700 text-slate-200 rounded-lg font-medium
                         hover:bg-slate-600 transition-all duration-300 hover:scale-[1.02]"
              >
                Back to Login
         </Link>
        </div>
      </div>

      {/* Add custom styles for animations */}
      <style jsx>{`
        @keyframes gradient-x {
          0%, 100% { transform: translateX(-50%) }
          50% { transform: translateX(50%) }
        }
        
        @keyframes gradient-y {
          0%, 100% { transform: translateY(-50%) }
          50% { transform: translateY(50%) }
        }

        @keyframes float {
          0%, 100% { transform: translate(0, 0) }
          25% { transform: translate(10px, -10px) }
          50% { transform: translate(-5px, 5px) }
          75% { transform: translate(-10px, -5px) }
        }

        @keyframes glow {
          0%, 100% { box-shadow: 0 0 20px rgba(59, 130, 246, 0.1) }
          50% { box-shadow: 0 0 30px rgba(59, 130, 246, 0.2) }
        }

        @keyframes shake {
          0%, 100% { transform: translateX(0) }
          25% { transform: translateX(-2px) }
          75% { transform: translateX(2px) }
        }

        .animate-gradient-x {
          animation: gradient-x 15s infinite;
        }

        .animate-gradient-y {
          animation: gradient-y 10s infinite;
        }

        .animate-float-slow {
          animation: float 8s infinite;
        }

        .animate-float-slower {
          animation: float 12s infinite;
        }

        .animate-float {
          animation: float 6s infinite;
        }

        .animate-glow {
          animation: glow 3s infinite;
        }

        .animate-shake {
          animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
        }

        ${[...Array(5)].map((_, i) => `
          @keyframes twinkle-${i} {
            0%, 100% { opacity: 0.3; transform: scale(1) }
            50% { opacity: 1; transform: scale(1.2) }
          }
          
          .animate-twinkle-${i} {
            animation: twinkle-${i} ${3 + i * 0.5}s infinite;
          }
        `).join('\n')}
      `}</style>
    </div>
  );
};

export default ForgotPassword;